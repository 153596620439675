.HomeHero{
  min-height: calc(100vh - 200px);
  background-color: #2B466F;
  overflow-x: hidden;
}

.slide{
  width: "600px";
}

.SwiperContainer {
  height: 300px;
  width: 800px ; 
  margin-right: -480px !important;
  animation: fadein .1s forwards;
  opacity: 0;
  animation-delay: 1s;
}

@keyframes fadein {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@media screen and (max-width: 1006px) {
  .SwiperContainer { 
    margin-top: 2em;
    margin-right: -25% !important;
    margin-left: 25% !important;
  }
}

.CloudDivider{
  height: 200px;
  background-color: #2B466F;
  background-image: url(/images/clouds.svg);
  background-repeat: repeat-x;
  background-position: bottom;
}